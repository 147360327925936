import * as React from 'react';
import {Config} from "../models/config";


interface Props {
    config: Config;
    fetchConfig(): void;
    updateConfig(config: Config): void;
    history: any;
}

export class ConfigPage extends React.Component<Props,{config:any}> {

    constructor(props: any) {
        super(props);

        this.state = {
            config: this.props.config
        };

        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.fetchConfig();
    }

    componentWillReceiveProps(nextProps: any) {
        if(nextProps.config){
            this.setState({config: nextProps.config});
        }
    }

    save() {
        this.props.updateConfig(this.state.config);
        this.props.history.push('/current');
    }

    cancel() {
        this.props.history.push('/current');
    }

    handleChange(event: any) {
        const config: Config = this.state.config;
        if(event.target.id === 'transcript1'){
            config.transcriptCall1 = event.target.value;
            this.setState({config: config});
        }else if(event.target.id === 'transcript2'){
            config.transcriptCall2 = event.target.value;
            this.setState({config: config});
        }else if(event.target.id === 'wasteActionCallText'){
            config.wasteActionCallText = event.target.value;
            this.setState({config: config});
        }else if(event.target.id === 'returnActionCallText'){
            config.returnActionCallText = event.target.value;
            this.setState({config: config});
        }else if(event.target.id === 'holdActionCallText'){
            config.holdActionCallText = event.target.value;
            this.setState({config: config});
        }else if(event.target.id === 'secondCallDelay'){
            config.secondCallDelay = event.target.value;
            this.setState({config: config});
        }else if(event.target.id === 'minutesUntilRetry'){
            config.minutesUntilRetry = event.target.value;
            this.setState({config: config});
        }else if(event.target.id === 'minutesUntilMissingEmail'){
            config.minutesUntilMissingEmail = event.target.value;
            this.setState({config: config});
        }
    }

    render() {
        return (
            <div className="configContainer">
                <button onClick={this.save} className="save">SAVE</button>
                <button onClick={this.cancel} className="cancel" >CANCEL</button>
                <h5 className="recallNotice">Current Configuration</h5>
                <form>
                    <div className="row">
                        <div className="col-sm-7">
                            <div className="form-group">
                                <label htmlFor="transcript1">First Call Transcript</label>
                                <textarea onChange={this.handleChange} className="form-control transcript" id="transcript1" value={this.state.config.transcriptCall1}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="transcript2">Second Call Transcript</label>
                                <textarea onChange={this.handleChange} className="form-control transcript" id="transcript2" value={this.state.config.transcriptCall2}/>
                            </div>
                        </div>
                        <div className="col-sm-5">
                            <div className="form-group">
                                <label htmlFor="wasteActionCallText">Waste/Destroy Action Transcript Text</label>
                                <textarea onChange={this.handleChange} className="actionText form-control" id="wasteActionCallText" value={this.state.config.wasteActionCallText}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="returnActionCallText">Return Action Transcript Text</label>
                                <textarea onChange={this.handleChange} className="actionText form-control" id="returnActionCallText" value={this.state.config.returnActionCallText}/>
                            </div>

                            <div className="form-group">
                                <label htmlFor="holdActionCallText">Hold for Pickup Action Transcript Text</label>
                                <textarea onChange={this.handleChange} className="actionText form-control" id="holdActionCallText" value={this.state.config.holdActionCallText}/>
                            </div>

                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="secondCallDelay">Delay after first call</label>
                                        <input onChange={this.handleChange} className="form-control" id="secondCallDelay" value={this.state.config.secondCallDelay} type="number"/><span className="minutes">Minutes</span>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="minutesUntilRetry">Delay for retry</label>
                                        <input onChange={this.handleChange} className="form-control" id="minutesUntilRetry" value={this.state.config.minutesUntilRetry} type="number"/><span className="minutes">Minutes</span>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="minutesUntilMissingEmail">Missing List Email</label>
                                        <input onChange={this.handleChange} className="form-control" id="minutesUntilMissingEmail" value={this.state.config.minutesUntilMissingEmail} type="number"/><span className="minutes">Minutes</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
};
