const loadingReducer = (state: boolean = false, action: any) => {
  switch(action.type) {
    case 'LOADING':
      return true
    case 'NOT_LOADING':
      return false
    default:
      return state
  }
}

export default loadingReducer;