import { connect } from 'react-redux';
import { State } from '../reducers';
import {Recall} from "../models";
import {
    fetchRecallAction,
    fetchTranscriptsAction,
    sendTranscriptPreviewAction,
    updateRecallAction
} from "../actions/recallAction";
import {UpdateRecall} from "./updateRecall";
import {fetchStoresAction} from "../actions/storesAction";


const mapStateToProps = (state: State) => ({
    recall: state.recall,
    stores: state.stores,
    transcripts: state.transcripts
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchRecall: (id: number) => dispatch(fetchRecallAction(id)),
    fetchStores: () => dispatch(fetchStoresAction()),
    fetchTranscripts: () => dispatch(fetchTranscriptsAction()),
    updateRecall: (recall: Recall) => dispatch(updateRecallAction(recall)),
    sendTranscript: (phoneNumber: string, transcriptText: string) => dispatch(sendTranscriptPreviewAction(phoneNumber, transcriptText)),
});

export const UpdateRecallContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateRecall);