import * as React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactDatePicker from "react-datepicker";
import "react-tabs/style/react-tabs.css";
import "react-datepicker/dist/react-datepicker.css";
import {Recall, Store} from "../models";
import Modal from 'react-modal';

import "@kenshooui/react-multi-select/dist/style.css";
// @ts-ignore
import MultiSelect from "@kenshooui/react-multi-select";
import {store} from "../store";
import {Config} from "../models/config";


const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

interface Props {
    stores: Store[],
    transcripts: Config;
    saveCreateRecall(recall: Recall, cb?: Function): void;
    sendTranscript(phoneNumber: string, transcriptText: string): void;
    fetchStores(): void;
    fetchTranscripts(): void;
}

const bannerList = {
  "All Divisions": () => true,
  "CF All": (store: any) => store.store.StoreIdFormatted.substring(0, 2) === '00',
  "CF Northeast": (store: any) => store.store.StoreIdFormatted.substring(0, 2) === '00' && store.store.State !== "FL",
  "CF Florida": (store: any) => store.store.StoreIdFormatted.substring(0, 2) === '00' && store.store.State === "FL",
  "Certified Oil": (store: any) => store.store.StoreIdFormatted.substring(0, 2) === '40',
  "Fastrac": (store: any) => store.store.StoreIdFormatted.substring(0, 2) === '50',
  "Kwik Shop": (store: any) => store.store.StoreIdFormatted.substring(0, 2) === '35',
  "Loaf N' Jug": (store: any) => store.store.StoreIdFormatted.substring(0, 2) === '75',
  "Minit Mart": (store: any) => store.store.StoreIdFormatted.substring(0, 2) === '60',
  "Quik Stop": (store: any) => store.store.StoreIdFormatted.substring(0, 2) === '55',
  "Tom Thumb": (store: any) => store.store.StoreIdFormatted.substring(0, 2) === '90',
  "Turkey Hill": (store: any) => store.store.StoreIdFormatted.substring(0, 2) === '95',
  "Turkey Hill Midwest": (store: any) => store.store.StoreIdFormatted.substring(0, 2) === '85',
}

function any<T>(preds: Array<(t: T) => boolean>, t: T) {
  for (const pred of preds) {
    if (pred(t)) {
      return true;
    }
  }
  return false;
}

export class CreateRecall extends React.Component<Props,{sendImmediately: boolean, readProducts: boolean,
    sendDate: Date | null, severityLevel: string, action: string, products: string[], currentProduct: string, loading: boolean,
    recallName: string, contactName: string, contactNumber: string, transcript1: string, transcript2: string, additionalMessage:string,
    stores: string[], modalIsOpen: boolean, previewPhoneNumber:string, previewTranscript:string, showSelectStores: boolean, items: any[], selectedItems: any[], selectedBanners: string[]}> {

    constructor(props: any) {
        super(props);

        const storeItems = [];
        var i = 0;
        for(const store of this.props.stores) {
            storeItems.push({id: i, label: `${store.StoreIdFormatted} - ${store.Address1}`, store: store});
            i++;
        }

        this.state = {
            action: '',
            additionalMessage: '',
            contactName: '',
            contactNumber: '',
            currentProduct: '',
            modalIsOpen: false,
            previewPhoneNumber: '',
            previewTranscript: '1',
            products: [],
            readProducts: true,
            loading: false,
            recallName: '',
            sendDate: null,
            sendImmediately: false,
            severityLevel: '',
            stores: [],
            transcript1: this.props.transcripts.transcriptCall1,
            transcript2: this.props.transcripts.transcriptCall2,
            showSelectStores: false,
            items: storeItems,
            selectedItems: [],
            selectedBanners: []
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.sendImmediatelyClick = this.sendImmediatelyClick.bind(this);
        this.readProductsClick = this.readProductsClick.bind(this);
        this.handleStoresChange = this.handleStoresChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePreviewChange = this.handlePreviewChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSeverityClick = this.handleSeverityClick.bind(this);
        this.handleActionsClick = this.handleActionsClick.bind(this);
        this.handleStoresClick = this.handleStoresClick.bind(this);
        this.addToProducts = this.addToProducts.bind(this);
        this.removeFromProducts = this.removeFromProducts.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.createRecall = this.createRecall.bind(this);
        this.previewTranscript = this.previewTranscript.bind(this);
        this.actionTranscriptText = this.actionTranscriptText.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.addStoreNumber = this.addStoreNumber.bind(this);
    }

    componentDidMount(): void {
        this.props.fetchTranscripts();
        this.props.fetchStores();
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.transcripts) {
            this.setState({transcript1: nextProps.transcripts.transcriptCall1, transcript2: nextProps.transcripts.transcriptCall2});
        }
        if (nextProps.stores){
            const storeItems = [];
            var i = 0;
            for(const store of nextProps.stores) {
                storeItems.push({id: i, label: `${store.StoreIdFormatted} - ${store.Address1}`, store: store});
                i++;
            }
            this.setState({stores: nextProps.stores, items: storeItems})
        }
    }

    handleStoresChange(selectedItems: any[]) {
        this.setState({ selectedItems });
    }

    openModal(){
        this.setState({modalIsOpen: true, previewTranscript: '1'});
    }

    closeModal = () => (e: any) => {
        this.setState({modalIsOpen: false, previewPhoneNumber: ''});
    }

    sendImmediatelyClick(event: any) {
        if(!this.state.sendImmediately){
            this.setState({sendImmediately: !this.state.sendImmediately, sendDate: null});
        }else {
            this.setState({sendImmediately: !this.state.sendImmediately});
        }

    }

    readProductsClick(event: any) {
        this.setState({readProducts: !this.state.readProducts});
    }

    handleChange(date: Date) {
        if(date > new Date()){
            this.setState({
                sendDate: date, sendImmediately: false
            });
        }
    }

    handlePreviewChange(event: any) {
        this.setState({previewPhoneNumber: event.target.value});
    }

    handleRadioChange(event: any) {
        this.setState({previewTranscript: event.target.value});
    }

    handleToggleLoading(onOff: boolean) {
      this.setState({loading: onOff});
    }

    handleTextChange(event: any) {
        if(event.target.name === 'product'){
            this.setState({currentProduct: event.target.value});
        }else if(event.target.name === 'name'){
            this.setState({recallName: event.target.value});
        }else if(event.target.name === 'contactName'){
            this.setState({contactName: event.target.value});
        }else if(event.target.name === 'contactNumber'){
            this.setState({contactNumber: event.target.value});
        }else if(event.target.name === 'transcript1'){
            this.setState({transcript1: event.target.value});
        }else if(event.target.name === 'transcript2'){
            this.setState({transcript2: event.target.value});
        }
    }

    handleSeverityClick = (value: string) => (e: any) => {
        this.setState({ severityLevel: value });
    }

    handleActionsClick = (value: string) => (e: any) => {
        this.setState({ action: value });
    }

    handleStoresClick = (value: string) => (e: any) => {
        e.preventDefault();
        if(value === 'show'){
            this.setState({showSelectStores: !this.state.showSelectStores});
        }else{
          let banners = this.state.selectedBanners;
          if (banners.find(x => x === value)) {
            banners = banners.filter(x => x !== value)
          } else {
            banners.push(value)
          }
          //@ts-ignore
          const preds = banners.map(x => bannerList[x])
          const selectedStores = this.state.items.filter(store => any(preds, store))
          this.setState({ selectedItems: selectedStores, selectedBanners: banners, showSelectStores: false });
        }
    }

    addToProducts = (value: string) => (e: any) => {
        if(value.trim().length > 0){
            this.state.products.push(value);
            this.setState({'currentProduct': ''});
        }
    }

    removeFromProducts = (index: number) => (e: any) => {
        this.state.products.splice(index,1);
        this.setState({products: this.state.products});
    }

    keyPress(e: any){
        if(e.keyCode == 13 && this.state.currentProduct.trim().length > 0) {
            this.state.products.push(this.state.currentProduct);
            this.setState({'currentProduct': ''});
        }
    }

    createRecall() {
        this.handleToggleLoading(true);
        const products = [];
        for(const product of this.state.products){
            products.push({'name': product});
        }

        const stores = [];
        for(const store of this.state.selectedItems){
            stores.push(store.store);
        }

        if(this.state.recallName && this.state.contactName && this.state.contactNumber && products.length > 0 && stores.length > 0 && (this.state.sendImmediately || this.state.sendDate)){
            const recall = {
                name: this.state.recallName,
                contactName: this.state.contactName,
                contactPhoneNumber: this.state.contactNumber,
                transcriptCall1: this.state.transcript1,
                transcriptCall2: this.state.transcript2,
                additionalMessage: this.state.additionalMessage,
                products: products,
                readProducts: this.state.readProducts,
                sendDate: (this.state.sendImmediately || !this.state.sendDate? new Date () : this.state.sendDate),
                severityLevel: this.state.severityLevel,
                stores: stores.map(x => ({ StoreIdFormatted: x.StoreIdFormatted, Phone: x.Phone })),
                action: this.state.action
            }
            this.props.saveCreateRecall(recall, () => {
              this.handleToggleLoading(false);
              window.location.href = '/current';
            });
        }
    }

    previewTranscript() {
        let transcript = (this.state.previewTranscript === '1' ? this.state.transcript1 : this.state.transcript2);
        transcript = transcript.replace(/(STORE_NUMBER)/g, '.');
        transcript = transcript.replace(/(USER_INPUT)/g, '');
        transcript = transcript.replace(/(ACTION)/g, `${this.actionTranscriptText(this.state.action)}.`);
        transcript = transcript.replace(/(CONTACT_NAME)/g,this.state.contactName);
        transcript = transcript.replace(/(CONTACT_PHONE_NUMBER)/g,this.state.contactNumber.split('').join(', '));
        transcript = transcript.replace(/(PHONE_NUMBER)/g,  '1 877 356 2335'.split('').join(', '));

        let products = '';
        if(this.state.readProducts){
            for (const product of this.state.products){
                products += `${product}. `
            }
        }

        transcript = transcript.replace('(PRODUCTS)', products);
        this.props.sendTranscript(this.state.previewPhoneNumber, transcript);
        this.setState({modalIsOpen: false, previewPhoneNumber: ''});
    }

    actionTranscriptText(action: string) : string {
        if(action === 'Waste/Destroy'){
            return this.props.transcripts.wasteActionCallText;
        } else if(action === 'Return'){
            return this.props.transcripts.returnActionCallText;
        } else if(action === 'Hold for Pickup'){
            return this.props.transcripts.holdActionCallText;
        }

        return '';
    }

    fileUpload(file: any) {
        this.setState({ selectedItems: [], showSelectStores: false });
        this.parseCSV(file[0], ',', (results: any[]) => {
            for(const resultArray of results) {
                for(const result of resultArray){
                    if(!isNaN(parseInt(result,10))){
                        this.addStoreNumber(result);
                    }
                }
            }
        });
    }

    addStoreNumber(storeNumber: string){
        for(const store of this.state.items){
            if(parseInt(store.store.StoreIdFormatted, 10) === parseInt(storeNumber,10)){
                const currentSelections = this.state.selectedItems;
                currentSelections.push(store);
                this.setState({selectedItems: currentSelections });
                break;
            }
        }
    }

    parseCSV(file: any, delimiter: string, callback: any) {
        let reader = new FileReader();

        reader.onload = function() {
            // @ts-ignore
            let lines = this.result.split('\n');

            let result = lines.map(function(line: any) {
                return line.split(delimiter);
            });

            callback(result);
        }

        reader.readAsText(file);
    }

    render() {
        return (
            <div>
                <button disabled={(this.state.loading || !this.state.recallName || !this.state.contactName || !this.state.contactNumber || this.state.products.length <= 0 || this.state.selectedItems.length <= 0 || (!this.state.sendImmediately && !this.state.sendDate))} className="save" onClick={this.createRecall}>{this.state.sendImmediately ? 'SEND CALL' : 'SAVE'}</button>
                <button className="cancel" onClick={() => {window.location.href = '/#/current'}}>CANCEL</button>
                <h5 className="recallNotice">Create New</h5>
                <div className="createContainer">
                    <div className="row">
                        <div className="col-4">
                            <div className="inputContainer">
                                <input name="name" value={this.state.recallName} placeholder="Recall item title *" onChange={this.handleTextChange}/>
                            </div>

                            <div className="inputContainer">
                                <input name="contactName" value={this.state.contactName} placeholder="Contact Name *" onChange={this.handleTextChange}/>
                            </div>
                            <div className="inputContainer">
                                <input name="contactNumber" value={this.state.contactNumber} placeholder="Contact Phone Number *" onChange={this.handleTextChange}/>
                            </div>
                            <div className="transcriptContainer">
                                <div className="play" onClick={this.openModal}></div>
                                <Tabs>
                                    <TabList>
                                        <Tab>Transcript 1</Tab>
                                        <Tab>Transcript 2</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <textarea className="transcriptHolder" placeholder="Call 1 Transcript"
                                                  name="transcript1" value={this.state.transcript1} onChange={this.handleTextChange}>
                                        </textarea>
                                    </TabPanel>
                                    <TabPanel>
                                        <textarea className="transcriptHolder" placeholder="Call 2 Transcript"
                                                  name="transcript2" value={this.state.transcript2} onChange={this.handleTextChange}>
                                        </textarea>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="inputContainer">
                                <input name="product" value={this.state.currentProduct} placeholder="Affected Item *" onChange={this.handleTextChange} onKeyDown={this.keyPress} />
                                <button onClick={this.addToProducts(this.state.currentProduct)} className="add"></button>
                            </div>

                            <div className="valueContainer">
                                <ul>
                                    {this.state.products.map((product, i) => {
                                        return <li key={i}> {product} <button onClick={this.removeFromProducts(i)} className="remove"></button></li>;
                                    })}
                                </ul>
                            </div>
                            <div className="pretty p-default p-round">
                                <input checked={this.state.readProducts} type="checkbox" onChange={this.readProductsClick}/>
                                <div className="state p-primary-o">
                                    <label className="read-affected-items">Read Affected Items on list</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="sendContainer pretty p-default p-round">
                                <input checked={this.state.sendImmediately} type="checkbox" onChange={this.sendImmediatelyClick}/>
                                <div className="state p-primary-o">
                                    <label className="send-immediately">Send Immediately</label>
                                </div>
                            </div>

                            <span className="or-schedule">or schedule</span>
                            <ReactDatePicker
                                selected={this.state.sendDate}
                                onChange={this.handleChange}
                                showTimeSelect
                                minDate={new Date()}
                                timeFormat="h:mm aa"
                                timeIntervals={5}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeCaption="time"
                                placeholderText="Enter a date/time to schedule a future recall."
                            />

                            <h3 className="createHeader">Severity Level</h3>
                            <div className="flex">
                                <button onClick={this.handleSeverityClick('Class 1')} className={"createRecallButton " + (this.state.severityLevel === 'Class 1' ? 'selectedSeverity' : '')}>Class 1</button>
                                <button onClick={this.handleSeverityClick('Class 2')} className={"createRecallButton " + (this.state.severityLevel === 'Class 2' ? 'selectedSeverity' : '')}>Class 2</button>
                                <button onClick={this.handleSeverityClick('Class 3')} className={"createRecallButton " + (this.state.severityLevel === 'Class 3' ? 'selectedSeverity' : '')}>Class 3</button>
                                <button onClick={this.handleSeverityClick('Withdrawal')} className={"createRecallButton " + (this.state.severityLevel === 'Withdrawal' ? 'selectedSeverity' : '')}>Withdrawal</button>
                            </div>
                            <h3 className="createHeader">Actions</h3>
                            <div className="flex">
                                <button onClick={this.handleActionsClick('Waste/Destroy')} className={"createRecallButton " + (this.state.action === 'Waste/Destroy' ? 'selectedRecallButton' : '')}>Waste/Destroy</button>
                                <button onClick={this.handleActionsClick('Return')} className={"createRecallButton " + (this.state.action === 'Return' ? 'selectedRecallButton' : '')}>Return</button>
                                <button onClick={this.handleActionsClick('Hold for Pickup')} className={"createRecallButton " + (this.state.action === 'Hold for Pickup' ? 'selectedRecallButton' : '')}>Hold for Pickup</button>
                            </div>
                            <h3 className="createHeader">Stores <span className="selectionCounts">{this.state.selectedItems.length}/{this.state.items.length}</span></h3>
                            <div className="flex">
                                <div>
                                  {Object.keys(bannerList).map(b => 
                                    <button key={b} onClick={this.handleStoresClick(b)} className={"createRecallButton " + (this.state.selectedBanners.find(x => x === b) ? 'selectedRecallButton' : '')}>{b}</button>
                                    )}
                                </div>
                            </div>
                            <a onClick={this.handleStoresClick('show')} className="selectStores" href="#">Select Stores</a>
                            <button onClick={() =>{
                                // @ts-ignore
                                document.getElementById('single').click()}
                            } className="import" >Import Excel</button>
                            <input type="file" id="single" accept=".csv" onChange={(e) => this.fileUpload(e.target.files)} />
                        </div>
                    </div>
                    <div className="row">
                        { this.state.showSelectStores ?
                            <div className="col-12 storesContainer">
                                <h1 className="storesTitle">Stores</h1>
                                <MultiSelect
                                    width="100%"
                                    items={this.state.items}
                                    selectedItems={this.state.selectedItems}
                                    onChange={this.handleStoresChange}
                                />
                            </div>: ""
                        }
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Preview"
                >
                    <form>
                        <label>Phone Number:</label>
                        <input id="phone" name="previewPhone" value={this.state.previewPhoneNumber} onChange={this.handlePreviewChange}/>
                        <input className="radio" checked type="radio" name="previewTranscript" value="1" onChange={this.handleRadioChange}/> Call Transcript 1 &nbsp;&nbsp;&nbsp;&nbsp;
                        <input className="radio" type="radio" name="previewTranscript" value="2" onChange={this.handleRadioChange}/> Call Transcript 2
                        <div className="buttonRow">
                            <button type="button" disabled={this.state.previewPhoneNumber.length !== 10} onClick={this.previewTranscript}>Call Now</button>
                            <button type="button" onClick={this.closeModal()}>Cancel</button>
                        </div>
                    </form>
                </Modal>
            </div>
        )
    }
};
