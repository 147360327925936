import { actionTypes } from '../constants/actionTypes';
import {Config} from "../models/config";

export const configReducer = (state:any = {}, action:any) => {
    switch (action.type) {
        case actionTypes.FETCH_CONFIG_COMPLETED:
            return handleFetchConfigCompleted(state, action.payload);
    }

    return state;
}

const handleFetchConfigCompleted = (state: Config, payload: Config): Config => {
    return payload;
};