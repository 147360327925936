import moment from "moment";
import * as React from "react";
import { Recall, Store } from "../models";
import { Doughnut } from "react-chartjs-2";
import Collapsible from "react-collapsible";
import { CSVLink } from "react-csv";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
  },
};

interface Props {
  recall: Recall;
  stores: any[];
  sendMarkCompleteAction(recallId: number): void;
  sendMarkDeletedAction(recallId: number, reason: string): void;
}

Modal.setAppElement("#root");

export class RecallDetail extends React.Component<
  Props,
  {
    completedStoreTotal: number;
    errorStoreTotal: number;
    chartData: any;
    totalUnitsLost: number;
    completedList: any[];
    incompleteList: any[];
    filteredCompletedList: any[];
    filteredIncompleteList: any[];
    csvData: any[];
    incompleteFlorida: number;
    completeFlorida: number;
    incompleteNorthEast: number;
    completeNorthEast: number;
    filterModalIsOpen: boolean;
    filterType: string;
    filterValue: string;
    filterValues: string[];
    filters: any[];
  }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      chartData: {
        datasets: [
          {
            data: [0, 0],
            backgroundColor: ["#77B200", "#FF5F5F"],
            hoverBackgroundColor: ["#77B200", "#FF5F5F"],
          },
        ],
        labels: ["Completed", "Incomplete"],
      },
      completedStoreTotal: 0,
      errorStoreTotal: 0,
      totalUnitsLost: 0,
      completedList: [],
      incompleteList: [],
      filteredCompletedList: [],
      filteredIncompleteList: [],
      csvData: [],
      incompleteFlorida: 0,
      completeFlorida: 0,
      incompleteNorthEast: 0,
      completeNorthEast: 0,
      filterModalIsOpen: false,
      filterType: "",
      filterValue: "",
      filterValues: [],
      filters: [],
    };

    this.markCompleted = this.markCompleted.bind(this);
    this.markDeleted = this.markDeleted.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.filterStores = this.filterStores.bind(this);
    this.onChange = this.onChange.bind(this);
    this.buildRecallData = this.buildRecallData.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.doFilter = this.doFilter.bind(this);
  }

  componentDidMount(): void {
    this.buildRecallData(this.props);
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.recall && Array.isArray(nextProps.recall.stores)) {
      this.buildRecallData(nextProps);
    }
  }

  formatDateTime(sendDate: Date): string {
    const date = moment(sendDate);
    return date.format("MMMM Do, YYYY - h:mma");
  }

  buildRecallData(propData: any) {
    let completed: any[] = [];
    let incomplete: any[] = [];
    let totalLost: number = 0;
    let csvData: any[] = [];
    let incompleteFlorida: number = 0;
    let completeFlorida: number = 0;
    let incompleteNorthEast: number = 0;
    let completeNorthEast: number = 0;
    csvData.push([
      "STORE",
      "COMPLETE",
      "GENERAL",
      "REGION",
      "AREA",
      "STRADDRESS",
      "CITYSTATE",
      "ZIP",
      "STATE",
      "TELEPHONE",
    ]);

    for (const store of propData.recall.stores) {
      for (const storeLookup of this.props.stores) {
        if (
          storeLookup.StoreIdFormatted === store.storeNumber ||
          storeLookup.StoreId === store.storeNumber
        ) {
          store.storeObject = storeLookup;
          break;
        }
      }
      if (store.completed) {
        totalLost += store.responseQuantity;
        completed.push(store);
        if (store.storeObject && store.storeObject.State === "FL") {
          completeFlorida++;
        } else {
          completeNorthEast++;
        }
      } else {
        incomplete.push(store);
        if (store.storeObject && store.storeObject.State === "FL") {
          incompleteFlorida++;
        } else {
          incompleteNorthEast++;
        }
      }

      //TODO Remove after testing
      if (store.storeObject) {
        csvData.push([
          store.storeNumber,
          store.responseQuantity,
          store.storeObject.GeneralManager,
          store.storeObject.RegionalManager,
          store.storeObject.DistrictManager,
          store.storeObject.Address1,
          `${store.storeObject.City}, ${store.storeObject.State}`,
          store.storeObject.Zip,
          store.storeObject.State,
          store.storeObject.Phone,
        ]);
      } else {
        csvData.push([
          store.storeNumber,
          store.responseQuantity,
          store.GeneralManager,
          store.RegionalManager,
          store.DistrictManager,
          store.Address1,
          `${store.City}, ${store.State}`,
          store.Zip,
          store.State,
          store.Phone,
        ]);
      }
    }

    this.setState({
      totalUnitsLost: totalLost,
      completedList: completed,
      incompleteList: incomplete,
      filteredCompletedList: completed,
      filteredIncompleteList: incomplete,
      completedStoreTotal: completed.length,
      chartData: {
        datasets: [
          {
            data: [completed.length, incomplete.length],
            backgroundColor: ["#77B200", "#FF5F5F"],
            hoverBackgroundColor: ["#77B200", "#FF5F5F"],
          },
        ],
        labels: ["Completed", "Incomplete"],
      },
      csvData: csvData,
      incompleteFlorida: incompleteFlorida,
      completeFlorida: completeFlorida,
      incompleteNorthEast: incompleteNorthEast,
      completeNorthEast: completeNorthEast,
    });
  }

  markCompleted() {
    if (
      this.props.recall.id &&
      // eslint-disable-next-line no-restricted-globals
      confirm(
        `Are you sure you want to mark ${this.props.recall.name} as completed?`
      )
    ) {
      this.props.sendMarkCompleteAction(this.props.recall.id);
      window.location.reload();
    }
  }

  markDeleted() {
    let reason = prompt(
      `Are you sure you want to mark ${this.props.recall.name} as deleted?`,
      "Reason"
    );
    if (this.props.recall.id && reason && reason !== "Reason") {
      this.props.sendMarkDeletedAction(this.props.recall.id, reason);
      window.location.reload();
    }
  }

  openModal() {
    this.setState({ filterModalIsOpen: true });
  }

  closeModal() {
    this.setState({ filterModalIsOpen: false });
  }

  removeFilter = (i: number) => (e: any) => {
    let filters = this.state.filters;
    filters.splice(i);
    this.doFilter(filters);
  };

  filterStores() {
    let filters = this.state.filters;
    filters.push({
      type: this.state.filterType,
      value: this.state.filterValue,
    });
    this.doFilter(filters);
  }

  doFilter(filters: any) {
    let incompleteStores = this.state.incompleteList;
    let completedStores = this.state.completedList;
    for (const filter of filters) {
      incompleteStores = incompleteStores.filter(
        (store) => store.storeObject[filter.type] == filter.value
      );
      completedStores = completedStores.filter(
        (store) => store.storeObject[filter.type] == filter.value
      );
    }
    this.setState({
      filters: filters,
      filterModalIsOpen: false,
      filterValue: "",
      filteredIncompleteList: incompleteStores,
      filteredCompletedList: completedStores,
    });
  }

  onChange(event: any) {
    if (event.target.name === "filterType" && this.props.recall.stores) {
      const filterValues: any[] = [];
      for (const store of this.props.recall.stores) {
        for (const storeLookup of this.props.stores) {
          if (storeLookup.StoreIdFormatted === store.storeNumber) {
            if (
              storeLookup[event.target.value] &&
              !filterValues.includes(storeLookup[event.target.value])
            ) {
              filterValues.push(storeLookup[event.target.value]);
            }
            break;
          }
        }
      }
      this.setState({
        filterValues: filterValues,
        filterType: event.target.value,
        filterValue: filterValues[0],
      });
    } else if (event.target.name === "filterValue") {
      this.setState({ filterValue: event.target.value });
    }
  }

  render() {
    return (
      <div className="detailContainer">
        <div className="row summary">
          <div className="col-8">
            <h1 className="detailTitle">{this.props.recall.name}</h1>

            <div className="tooltips">
              <a
                className="editButton"
                href={`/#/recall/${this.props.recall.id}`}
              >
                <i className="fa fa-lg fa-pencil"></i>
              </a>
              <span className="tooltiptext">Edit Recall</span>
            </div>
            {!this.props.recall.deleted &&
            !this.props.recall.completed &&
            this.props.recall.sendDate &&
            new Date(this.props.recall.sendDate) < new Date() ? (
              <div className="tooltips">
                <span className="checkButton" onClick={this.markCompleted}>
                  <i className="fa fa-lg fa-check"></i>
                </span>
                <span className="tooltiptext">Mark Completed</span>
              </div>
            ) : (
              ""
            )}

            {!this.props.recall.deleted &&
            !this.props.recall.completed &&
            this.props.recall.sendDate &&
            new Date(this.props.recall.sendDate) < new Date() ? (
              <div className="tooltips">
                <span className="deleteButton" onClick={this.markDeleted}>
                  <i className="fa fa-lg fa-ban"></i>
                </span>
                <span className="tooltiptext">Stop Recall</span>
              </div>
            ) : (
              ""
            )}
            <br />

            <span className="sendDate">
              {this.props.recall.sendDate
                ? this.formatDateTime(this.props.recall.sendDate)
                : ""}{" "}
              EST
            </span>
            <span className="status">
              {this.props.recall.completed
                ? "Completed"
                : this.props.recall.sendDate &&
                  new Date(this.props.recall.sendDate) < new Date()
                ? "Active"
                : "Scheduled"}
            </span>
            <div className="row affectedContainer">
              <div className="col-6 affectedProducts">
                <h4 className="affectedTitle">Affected items</h4>
                <ul>
                  {this.props.recall.products &&
                    this.props.recall.products.map((product, i) => {
                      return <li key={i}> {product.name}</li>;
                    })}
                </ul>
              </div>
              <div className="col-6">
                <h4 className="affectedTitle">Affected Stores</h4>
                <p>
                  Northeast{" "}
                  <span>
                    {this.state.completeNorthEast}/
                    {this.state.completeNorthEast +
                      this.state.incompleteNorthEast}
                  </span>
                </p>
                <p>
                  Florida{" "}
                  <span>
                    {this.state.completeFlorida}/
                    {this.state.completeFlorida + this.state.incompleteFlorida}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="doughnutContainer">
            <div className="confirmedPercent">
              <span>
                {this.state.incompleteList.length === 0
                  ? "100"
                  : Math.trunc(
                      (this.state.completedList.length /
                        (this.state.incompleteList.length +
                          this.state.completedList.length)) *
                        100
                    )}
                %
              </span>
              <div className="confirmedLabel">CONFIRMED</div>
            </div>
            <Doughnut
              data={this.state.chartData}
              options={{ legend: { display: false }, cutoutPercentage: 85 }}
              height={200}
              width={350}
            />
            <p className="unitsLost">
              {this.state.totalUnitsLost} Total Units Lost
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CSVLink
              filename={`${this.props.recall.name}-export.csv`}
              data={this.state.csvData}
              className="export"
              target="_blank"
            >
              Export
            </CSVLink>
            <span onClick={this.openModal} className="filter">
              Filter
            </span>
            {this.state.filters.map((filter, i) => {
              return (
                <span className="currentFilter" key={i}>
                  {filter.value}
                  <i onClick={this.removeFilter(i)} className="removeWhite"></i>
                </span>
              );
            })}

            <span>
              <h4 className="callsLog">Calls Log</h4>{" "}
              <span className="callsTotals">
                Completed: {this.state.completedStoreTotal} &nbsp;&nbsp; Errors:{" "}
                <span className="errorLabel">{this.state.errorStoreTotal}</span>
              </span>
            </span>
            <div className="accordionContainer">
              <Collapsible trigger="Incomplete">
                <table>
                  <tbody>
                    {this.state.filteredIncompleteList.map((store, i) => {
                      return (
                        <tr key={i} className="incompleteRow">
                          <td className="title">
                            {store.storeNumber} -{" "}
                            {store.storeObject
                              ? store.storeObject.Address1
                              : store.Address1}{" "}
                            {store.storeObject
                              ? store.storeObject.Address2
                              : ""}
                          </td>
                          <td className="lastAttempt">
                            Last Attempt:{" "}
                            {store.secondCallLastSent
                              ? moment(store.secondCallLastSent).format(
                                  "MMMM Do, YYYY - h:mma"
                                )
                              : "None"}
                          </td>
                          <td className="incomplete">Incomplete</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Collapsible>
              <Collapsible trigger="Complete" className="complete">
                <table>
                  <tbody>
                    {this.state.filteredCompletedList.map((store, i) => {
                      return (
                        <tr key={i} className="completeRow">
                          <td className="title">
                            {store.storeNumber} -{" "}
                            {store.storeObject
                              ? store.storeObject.Address1
                              : store.Address1}
                          </td>
                          <td className="completed">
                            Completed:{" "}
                            {store.completedDate
                              ? moment(store.completedDate).format(
                                  "MMMM Do, YYYY - h:mma"
                                )
                              : "None"}{" "}
                          </td>
                          <td className="lost">
                            Units lost:
                            <span className="lostCount">
                              {store.responseQuantity}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Collapsible>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.filterModalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Filter"
          style={customStyles}
        >
          <h2>Filter</h2>
          <form>
            <div>
              <label>Filter Type:</label>
              <select
                onChange={this.onChange}
                name="filterType"
                className="form-control"
              >
                <option>Select One</option>
                <option value="GeneralManager">General Manager</option>
                <option value="RegionalManager">Regional Manager</option>
                <option value="DistrictManager">District Manager</option>
                <option value="State">State</option>
              </select>
            </div>
            <div>
              <label>Filter Value:</label>
              <select
                onChange={this.onChange}
                name="filterValue"
                className="form-control"
              >
                {this.state.filterValues.map((filterValue, i) => {
                  return <option key={i}> {filterValue}</option>;
                })}
              </select>
            </div>
            <div className="buttonRow">
              <button
                type="button"
                disabled={!this.state.filterType && !this.state.filterValue}
                onClick={this.filterStores}
              >
                Filter
              </button>
              <button type="button" onClick={this.closeModal}>
                Cancel
              </button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}
