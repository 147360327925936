import React from 'react';

const Loading = ({active = false}: {active?: boolean}) => {
  
  return <>
  {
    (active && <span style={{fontSize: '17px', marginLeft: '20px', color: '#3b82d7'}}>Loading, please wait...</span>) || ''
  }
  </>
}

export default Loading;