import axios from "axios";
import { actionTypes } from "../constants/actionTypes";
import { serverUrl } from "../serverUrl";
import { Config } from "../models/config";
const client = axios.create({ baseURL: serverUrl });

client.interceptors.request.use((config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      authorization: window.localStorage.getItem("okta-token-storage"),
    },
  };
}, Promise.reject);

export const fetchConfigAction = () => (dispatch: any) => {
  client.get(`/api/GetConfig`).then(
    (response: any) => {
      dispatch(fetchConfigCompleted(response.data[0]));
    },
    (error) => {
      dispatch(fetchConfigCompleted(null));
    }
  );
};

const fetchConfigCompleted = (config: any) => ({
  payload: config,
  type: actionTypes.FETCH_CONFIG_COMPLETED,
});

export const updateConfigAction = (config: Config) => (dispatch: any) => {
  client.post(`/api/UpdateConfig`, config).then(
    (response: any) => {
      dispatch(updateConfigCompleted(response.data));
    },
    (error) => {
      dispatch(updateConfigCompleted({}));
    }
  );
};

const updateConfigCompleted = (response: any) => ({
  payload: response,
  type: actionTypes.POST_CONFIG_COMPLETED,
});
