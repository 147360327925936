import { connect } from 'react-redux';
import { State } from './reducers';
import {App} from "./App";
import {fetchAllRecallsAction} from "./actions/recallAction";


const mapStateToProps = (state: State) => ({
    allRecalls: state.allRecalls
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchAllRecalls: () => dispatch(fetchAllRecallsAction()),
});

export const AppContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);