import * as React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { RecallsListContainer } from "./recallsList/recallsListContainer";
import { store } from "./store";
import { ConfigPageContainer } from "./configPage";
import { UpdateRecallContainer } from "./updateRecallPage/updateRecallContainer";
import { TimelinePageContainer } from "./timelinePage";
import { AppContainer } from "./appContainer";
import { CreateRecallContainer } from "./createPage";
import { Nav } from "./nav";
import { useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";

const links = [
  {
    link: "/current",
    title: "Current",
  },
  {
    link: "/upcoming",
    title: "Upcoming",
  },
  {
    link: "/past",
    title: "Past",
  },
];

export const AppRouter = (props: any) => {
  const isAuthenticated = useIsAuthenticated();


  return (
          <Provider store={store}>
            <Router>
                <Nav links={links} msalInstance={props.msalInstance} isAuthenticated={isAuthenticated}/>   
                {isAuthenticated && (<div className="container-fluid">
                  <Route component={AppContainer} />
                  {links.map((x) => (
                    <Route
                      path={x.link}
                      key={x.title}
                      exact={true}
                      render={() => <RecallsListContainer recallType={x.title} />}
                    />
                  ))}
                  <Route
                    path="/config"
                    exact={true}
                    component={ConfigPageContainer}
                  />
                  <Route
                    exact
                    path="/recall"
                    component={CreateRecallContainer}
                  />
                  <Route path="/recall/:id" component={UpdateRecallContainer} />
                  <Route path="/timeline" component={TimelinePageContainer} />

                  {/* <Route path={loginPath} component={LoginCallback} /> */}

                  <Route
                    path="/"
                    exact={true}
                    render={() => <Redirect to="/current" />}
                  />
                </div>
                )}
            </Router>
          </Provider>
  );
};
