import * as React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactDatePicker from "react-datepicker";
import "react-tabs/style/react-tabs.css";
import "react-datepicker/dist/react-datepicker.css";
import {Recall, Store} from "../models";
import Modal from 'react-modal';

import "@kenshooui/react-multi-select/dist/style.css";
// @ts-ignore
import MultiSelect from "@kenshooui/react-multi-select";
import {store} from "../store";
import {Config} from "../models/config";


const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

interface Props {
    location: any,
    transcripts: Config,
    recall: Recall,
    stores: Store[],
    fetchRecall(id: number): void;
    fetchStores(): void;
    fetchTranscripts(): void;
    updateRecall(recall: Recall): void;
    sendTranscript(phoneNumber: string, transcriptText: string): void;
}

export class UpdateRecall extends React.Component<Props,{recall: Recall, sendImmediately: boolean, storeSelection: string, currentProduct: string,
    modalIsOpen: boolean, previewPhoneNumber:string, previewTranscript:string, showSelectStores: boolean, items: any[], selectedItems: any[]}> {

    constructor(props: any) {
        super(props);

        const storeItems: any[] = [];
        var i = 0;
        for(const store of this.props.stores) {
            storeItems.push({id: i, label: `${store.StoreIdFormatted} - ${store.Address1}`, store: store});
            i++;
        }

        this.state = {
            recall: this.props.recall,
            currentProduct: '',
            modalIsOpen: false,
            previewPhoneNumber: '',
            previewTranscript: '1',
            sendImmediately: false,
            storeSelection: '',
            showSelectStores: false,
            items: storeItems,
            selectedItems: []
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.sendImmediatelyClick = this.sendImmediatelyClick.bind(this);
        this.readProductsClick = this.readProductsClick.bind(this);
        this.handleStoresChange = this.handleStoresChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePreviewChange = this.handlePreviewChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSeverityClick = this.handleSeverityClick.bind(this);
        this.handleActionsClick = this.handleActionsClick.bind(this);
        this.handleStoresClick = this.handleStoresClick.bind(this);
        this.addToProducts = this.addToProducts.bind(this);
        this.removeFromProducts = this.removeFromProducts.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.updateRecall = this.updateRecall.bind(this);
        this.previewTranscript = this.previewTranscript.bind(this);
        this.actionTranscriptText = this.actionTranscriptText.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.addStoreNumber = this.addStoreNumber.bind(this);
    }

    componentDidMount(): void {
        this.props.fetchRecall(this.props.location.pathname.toString().replace('/recall/',''));
        this.props.fetchStores();
        this.props.fetchTranscripts();
    }

    componentWillReceiveProps(nextProps: any) {
        if(nextProps.recall){
            this.setState({recall: nextProps.recall});
        }

        if(nextProps.stores){
            const storeItems: any[] = [];
            const selectedItems: any[] = [];
            var i = 0;
            for(const store of nextProps.stores) {
                storeItems.push({id: i, label: `${store.StoreIdFormatted} - ${store.Address1}`, store: store});
                if(nextProps.recall.stores){
                    for(const storeRecall of nextProps.recall.stores){
                        if(storeRecall.storeNumber === store.StoreIdFormatted){
                            selectedItems.push({id: i, label: `${store.StoreIdFormatted} - ${store.Address1}`, store: store});
                        }
                    }
                }

                i++;
            }
            this.setState({items: storeItems, selectedItems: selectedItems});
        }
    }

    handleStoresChange(selectedItems: any[]) {
        this.setState({ selectedItems });
    }

    openModal(){
        this.setState({modalIsOpen: true});
    }

    closeModal = () => (e: any) => {
        this.setState({modalIsOpen: false, previewPhoneNumber: ''});
    }

    sendImmediatelyClick(event: any) {
        if(!this.state.sendImmediately){
            this.state.recall.sendDate = undefined;
        }

        this.setState({sendImmediately: !this.state.sendImmediately, recall: this.state.recall});
    }

    readProductsClick(event: any) {
        this.state.recall.readProducts = !this.state.recall.readProducts;
        this.setState({ recall: this.state.recall });
    }

    handleChange(date: Date) {
        this.state.recall.sendDate = date;
        this.setState({recall: this.state.recall, sendImmediately: false});
    }

    handlePreviewChange(event: any) {
        this.setState({previewPhoneNumber: event.target.value});
    }

    handleRadioChange(event: any) {
        this.setState({previewTranscript: event.target.value});
    }

    handleTextChange(event: any) {
        let recall = this.state.recall;
        if(event.target.name === 'product'){
            this.setState({currentProduct: event.target.value});
        }else if(event.target.name === 'name'){
            recall.name = event.target.value;
            this.setState({recall: recall});
        }else if(event.target.name === 'contactName'){
            recall.contactName = event.target.value;
            this.setState({recall: recall});
        }else if(event.target.name === 'contactNumber'){
            recall.contactPhoneNumber = event.target.value;
            this.setState({recall: recall});
        }else if(event.target.name === 'transcript1'){
            recall.transcriptCall1 = event.target.value;
            this.setState({recall: recall});
        }else if(event.target.name === 'transcript2'){
            recall.transcriptCall2 = event.target.value;
            this.setState({recall: recall});
        }
    }

    handleSeverityClick = (value: string) => (e: any) => {
        this.state.recall.severityLevel = value;
        this.setState({ recall: this.state.recall });
    }

    handleActionsClick = (value: string) => (e: any) => {
        this.state.recall.action = value;
        this.setState({ recall: this.state.recall });
    }

    handleStoresClick = (value: string) => (e: any) => {
        e.preventDefault();
        if(value === 'show' && this.state.recall.sendDate && new Date(this.state.recall.sendDate) > new Date()){
            this.setState({showSelectStores: !this.state.showSelectStores});
        }else{
            let selectedStores = [];
            if(value === 'All' ){
                selectedStores = this.state.items;
            }else{
                for(const store of this.state.items){
                    if(value === 'Florida' && store.store.State === 'FL'){
                        selectedStores.push(store);
                    } else if(value === 'Northeast' && store.store.State !== 'FL'){
                        selectedStores.push(store);
                    }
                }
            }

            this.setState({ storeSelection: value, selectedItems: selectedStores, showSelectStores: false });
        }
    }

    addToProducts = (value: string) => (e: any) => {
        if(value.length > 0){
            this.state.recall.products.push({name: value});
            this.setState({'currentProduct': ''});
        }
    }

    removeFromProducts = (index: number) => (e: any) => {
        if(this.state.recall.sendDate && new Date(this.state.recall.sendDate) > new Date()){
            this.state.recall.products.splice(index,1);
            this.setState({recall: this.state.recall});
        }
    }

    keyPress(e: any){
        if(e.keyCode == 13 && this.state.currentProduct.length > 0) {
            this.state.recall.products.push({'name': this.state.currentProduct});
            this.setState({'currentProduct': ''});
        }
    }

    updateRecall() {
        const stores = [];
        for(const store of this.state.selectedItems){
            stores.push(store.store);
        }

        if(this.state.recall.name && this.state.recall.contactName && this.state.recall.contactPhoneNumber && this.state.recall.products.length > 0 && stores.length > 0 && (this.state.sendImmediately || this.state.recall.sendDate)){
            let recall = this.state.recall;
            recall.sendDate = (this.state.sendImmediately || !this.state.recall.sendDate? new Date () : this.state.recall.sendDate);
            recall.stores = stores;
            this.props.updateRecall(recall);
            window.location.href = '/#/current';
        }
    }

    previewTranscript() {
        let transcript = (this.state.previewTranscript === '1' ? this.state.recall.transcriptCall1 : this.state.recall.transcriptCall2);
        transcript = transcript.replace(/(STORE_NUMBER)/g, '.');
        transcript = transcript.replace(/(USER_INPUT)/g, '');
        transcript = transcript.replace(/(ACTION)/g, `${this.actionTranscriptText(this.state.recall.action)}.`);
        transcript = transcript.replace(/(CONTACT_NAME)/g,this.state.recall.contactName);
        transcript = transcript.replace(/(CONTACT_PHONE_NUMBER)/g,this.state.recall.contactPhoneNumber.split('').join(' '));
        transcript = transcript.replace(/(PHONE_NUMBER)/g,  '1 877 356 2335'.split('').join(' '));

        let products = '';
        if(this.state.recall.readProducts){
            for (const product of this.state.recall.products){
                products += `${product.name}. `
            }
        }

        transcript = transcript.replace('(PRODUCTS)', products);
        this.props.sendTranscript(this.state.previewPhoneNumber, transcript);
        this.setState({modalIsOpen: false, previewPhoneNumber: ''});
    }

    actionTranscriptText(action: string) : string {
        if(action === 'Waste/Destroy'){
            return this.props.transcripts.wasteActionCallText;
        } else if(action === 'Return'){
            return this.props.transcripts.returnActionCallText;
        } else if(action === 'Hold for Pickup'){
            return this.props.transcripts.holdActionCallText;
        }

        return '';
    }

    fileUpload(file: any) {
        this.setState({ selectedItems: [] });
        this.parseCSV(file[0], ',', (results: any[]) => {
            for(const resultArray of results) {
                for(const result of resultArray){
                    if(!isNaN(result)){
                        this.addStoreNumber(result);
                    }
                }
            }
        });
    }

    addStoreNumber(storeNumber: string){
        for(const store of this.state.items){
            if(store.store.StoreId == storeNumber){
                const currentSelections = this.state.selectedItems;
                currentSelections.push(store);
                this.setState({selectedItems: currentSelections });
                break;
            }
        }
    }

    parseCSV(file: any, delimiter: string, callback: any) {
        let reader = new FileReader();

        reader.onload = function() {
            // @ts-ignore
            let lines = this.result.split('\n');

            let result = lines.map(function(line: any) {
                return line.split(delimiter);
            });

            callback(result);
        }

        reader.readAsText(file);
    }

    render() {
        return (
            <div>
                <button disabled={(!this.state.recall.name || !this.state.recall.contactName || !this.state.recall.contactPhoneNumber || this.state.recall.products.length <= 0 || this.state.selectedItems.length <= 0 || (!this.state.sendImmediately && !this.state.recall.sendDate) || (this.state.recall.sendDate && new Date(this.state.recall.sendDate) < new Date()))} className="save" onClick={this.updateRecall}>{this.state.sendImmediately ? 'SEND CALL' : 'SAVE'}</button>
                <button className="cancel" onClick={() => {window.location.href = '/#/current'}}>CANCEL</button>
                <h5 className="recallNotice">{this.state.recall.completed ? 'Completed Recall' : this.state.recall.sendDate && new Date(this.state.recall.sendDate) < new Date() ? 'In Progress (Not Editable)' : 'Update Recall'}</h5>
                <div className="createContainer">
                    <div className="row">
                        <div className="col-4">
                            <div className="inputContainer">
                                <input disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} name="name" value={this.state.recall.name} placeholder="Recall item title *" onChange={this.handleTextChange}/>
                            </div>

                            <div className="inputContainer">
                                <input disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} name="contactName" value={this.state.recall.contactName} placeholder="Contact Name *" onChange={this.handleTextChange}/>
                            </div>
                            <div className="inputContainer">
                                <input disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} name="contactNumber" value={this.state.recall.contactPhoneNumber} placeholder="Contact Phone Number *" onChange={this.handleTextChange}/>
                            </div>
                            <div className="transcriptContainer">
                                <div className="play" onClick={this.openModal}></div>
                                <Tabs>
                                    <TabList>
                                        <Tab>Transcript 1</Tab>
                                        <Tab>Transcript 2</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <textarea disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} className="transcriptHolder" placeholder="Call 1 Transcript"
                                                  name="transcript1" value={this.state.recall.transcriptCall1} onChange={this.handleTextChange}>
                                        </textarea>
                                    </TabPanel>
                                    <TabPanel>
                                        <textarea disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} className="transcriptHolder" placeholder="Call 2 Transcript"
                                                  name="transcript2" value={this.state.recall.transcriptCall2} onChange={this.handleTextChange}>
                                        </textarea>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="inputContainer">
                                <input disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} name="product" value={this.state.currentProduct} placeholder="Affected Item *" onChange={this.handleTextChange} onKeyDown={this.keyPress} />
                                <button onClick={this.addToProducts(this.state.currentProduct)} className="add"></button>
                            </div>

                            <div className="valueContainer">
                                <ul>
                                    { this.state.recall.products ? this.state.recall.products.map((product, i) => {
                                        return <li key={i}> {product.name} <button onClick={this.removeFromProducts(i)} className="remove"></button></li>;
                                    }) : <li>Please add a product.</li>}
                                </ul>
                            </div>
                            <div className="pretty p-default p-round">
                                <input disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} checked={this.state.recall.readProducts} type="checkbox" onChange={this.readProductsClick}/>
                                <div className="state p-primary-o">
                                    <label className="read-affected-items">Read Affected Items on list</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="sendContainer pretty p-default p-round">
                                <input disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} checked={this.state.sendImmediately} type="checkbox" onChange={this.sendImmediatelyClick}/>
                                <div className="state p-primary-o">
                                    <label className="send-immediately">Send Immediately</label>
                                </div>
                            </div>

                            <span className="or-schedule">or schedule</span>
                            <ReactDatePicker
                                disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true}
                                selected={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) : undefined}
                                onChange={this.handleChange}
                                showTimeSelect
                                minDate={new Date()}
                                timeFormat="h:mm aa"
                                timeIntervals={5}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeCaption="time"
                                placeholderText="Enter a date/time to schedule a future recall."
                            />

                            <h3 className="createHeader">Severity Level</h3>
                            <div className="flex">
                                <button disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} onClick={this.handleSeverityClick('Class 1')} className={"createRecallButton " + (this.state.recall.severityLevel === 'Class 1' ? 'selectedSeverity' : '')}>Class 1</button>
                                <button disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} onClick={this.handleSeverityClick('Class 2')} className={"createRecallButton " + (this.state.recall.severityLevel === 'Class 2' ? 'selectedSeverity' : '')}>Class 2</button>
                                <button disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} onClick={this.handleSeverityClick('Class 3')} className={"createRecallButton " + (this.state.recall.severityLevel === 'Class 3' ? 'selectedSeverity' : '')}>Class 3</button>
                                <button disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} onClick={this.handleSeverityClick('Withdrawal')} className={"createRecallButton " + (this.state.recall.severityLevel === 'Withdrawal' ? 'selectedSeverity' : '')}>Withdrawal</button>
                            </div>
                            <h3 className="createHeader">Actions</h3>
                            <div className="flex">
                                <button disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} onClick={this.handleActionsClick('Waste/Destroy')} className={"createRecallButton " + (this.state.recall.action === 'Waste/Destroy' ? 'selectedRecallButton' : '')}>Waste/Destroy</button>
                                <button disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} onClick={this.handleActionsClick('Return')} className={"createRecallButton " + (this.state.recall.action === 'Return' ? 'selectedRecallButton' : '')}>Return</button>
                                <button disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} onClick={this.handleActionsClick('Hold for Pickup')} className={"createRecallButton " + (this.state.recall.action === 'Hold for Pickup' ? 'selectedRecallButton' : '')}>Hold for Pickup</button>
                            </div>
                            <h3 className="createHeader">Stores <span className="selectionCounts">{this.state.selectedItems.length}/{this.state.items.length}</span></h3>
                            <div className="flex">
                                <button disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} onClick={this.handleStoresClick('All')} className={"createRecallButton " + (this.state.storeSelection === 'All' ? 'selectedRecallButton' : '')}>All</button>
                                <button disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} onClick={this.handleStoresClick('Northeast')} className={"createRecallButton " + (this.state.storeSelection === 'Northeast' ? 'selectedRecallButton' : '')}>Northeast</button>
                                <button disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} onClick={this.handleStoresClick('Florida')} className={"createRecallButton " + (this.state.storeSelection === 'Florida' ? 'selectedRecallButton' : '')}>Florida</button>
                                <a onClick={this.handleStoresClick('show')} className="selectStores" href="#">Select Stores</a>
                            </div>
                            <button disabled={this.state.recall.sendDate ? new Date(this.state.recall.sendDate) < new Date(): true} onClick={() =>{
                                if(this.state.recall.sendDate && new Date(this.state.recall.sendDate) > new Date()){
                                    // @ts-ignore
                                    document.getElementById('single').click();
                                }
                            }} className="import" >Import Excel</button>
                            <input type="file" id="single" accept=".csv" onChange={(e) => this.fileUpload(e.target.files)} />
                        </div>
                    </div>
                    <div className="row">
                        { this.state.showSelectStores ?
                            <div className="col-12 storesContainer">
                                <h1 className="storesTitle">Stores</h1>
                                <MultiSelect
                                    width="100%"
                                    items={this.state.items}
                                    selectedItems={this.state.selectedItems}
                                    onChange={this.handleStoresChange}
                                />
                            </div>: ""
                        }
                    </div>
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Preview"
                >
                    <form>
                        <label>Phone Number:</label>
                        <input id="phone" name="previewPhone" value={this.state.previewPhoneNumber} onChange={this.handlePreviewChange}/>
                        <input className="radio" checked type="radio" name="previewTranscript" value="1" onChange={this.handleRadioChange}/> Call Transcript 1 &nbsp;&nbsp;&nbsp;&nbsp;
                        <input className="radio" type="radio" name="previewTranscript" value="2" onChange={this.handleRadioChange}/> Call Transcript 2
                        <div className="buttonRow">
                            <button type="button" disabled={this.state.previewPhoneNumber.length !== 10} onClick={this.previewTranscript}>Call Now</button>
                            <button type="button" onClick={this.closeModal()}>Cancel</button>
                        </div>
                    </form>
                </Modal>
            </div>
        )
    }
};
