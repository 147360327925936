import { PublicClientApplication } from "@azure/msal-browser";
import { Link } from "react-router-dom";

const handleLogin = async (msalInstance:PublicClientApplication) => {
  try {
    const response = await msalInstance.loginPopup();
    console.log(response); // Here, you can access the access token and other user information
  } catch (error) {
    console.log(error);
  }
};

const handleLogout = async (msalInstance:PublicClientApplication) => {
  try {
    const response = await msalInstance.logout()
    console.log(response); // Here, you can access the access token and other user information
  } catch (error) {
    console.log(error);
  }
};

export const Nav = (props: any) => (
  <nav>
    <div className="logo"></div>
    <ol>
      {props.isAuthenticated ? (
        <>
          {props.links.map((x: any) => (
            <li key={x.link}>
              <Link to={x.link}>{x.title}</Link>
            </li>
          ))}

          <li>
            <button
              className="btn-link"
              onClick={() => handleLogout(props.msalInstance)}
            >
              Logout
            </button>
          </li>
        </>
      ) : (
        <li>
          <button
            className="btn-link"
            onClick={async () => await handleLogin(props.msalInstance)}
          >
            Log in
          </button>
        </li>
      )}
    </ol>
  </nav>
)
