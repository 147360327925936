import axios from "axios";
import { actionTypes } from "../constants/actionTypes";
import { Store } from "../models/store";
import { serverUrl } from "../serverUrl";

const client = axios.create({ baseURL: serverUrl });

client.interceptors.request.use((config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      authorization: window.localStorage.getItem("okta-token-storage"),
    },
  };
}, Promise.reject);

export const fetchStoresAction = () => (dispatch: any) => {
  client.get(`/api/GetStores`).then(
    (response: any) => {
      dispatch(fetchStoresCompleted(response.data));
    },
    (error) => {
      dispatch(fetchStoresCompleted([]));
    }
  );
};

const fetchStoresCompleted = (stores: Store[]) => ({
  payload: stores,
  type: actionTypes.FETCH_STORES_COMPLETED,
});
