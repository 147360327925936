import axios from "axios";
import { actionTypes } from "../constants/actionTypes";
import { serverUrl } from "../serverUrl";
import { Recall } from "../models";
import { Config } from "../models/config";
const client = axios.create({ baseURL: serverUrl });

client.interceptors.request.use((config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      authorization: window.localStorage.getItem("okta-token-storage"),
    },
  };
}, Promise.reject);

export const purgeTwilioQueue = () => {
  client.get('/api/purge-twilio-queue')
  .then((response: any) => {
    alert('Success! Outbound call queue is empty.');
  })
}

export const fetchAllRecallsAction = () => (dispatch: any) => {
  client.get(`/api/GetAllRecalls`).then(
    (response: any) => {
      dispatch(fetchAllRecallsCompleted(response.data));
    },
    (error) => {
      dispatch(fetchAllRecallsCompleted([]));
    }
  );
};

const fetchAllRecallsCompleted = (recalls: any[]) => ({
  payload: recalls,
  type: actionTypes.FETCH_ALL_RECALLS_COMPLETED,
});

export const fetchRecallsAction = (type: string) => (dispatch: any) => {
  client.get(`/api/GetRecalls?type=${type}`).then(
    (response: any) => {
      dispatch(fetchRecallsCompleted(response.data));
    },
    (error) => {
      dispatch(fetchRecallsCompleted([]));
    }
  );
};

const fetchRecallsCompleted = (recalls: any[]) => ({
  payload: recalls,
  type: actionTypes.FETCH_RECALLS_COMPLETED,
});

export const fetchRecallAction = (id: number) => (dispatch: any) => {
  client.get(`/api/GetRecall?id=${id}`).then(
    (response: any) => {
      dispatch(fetchRecallCompleted(response.data));
    },
    (error) => {
      dispatch(fetchRecallCompleted({} as Recall));
    }
  );
};

const fetchRecallCompleted = (recall: Recall) => ({
  payload: recall,
  type: actionTypes.FETCH_RECALL_COMPLETED,
});

export const fetchTranscriptsAction = () => (dispatch: any) => {
  client.get(`/api/GetTranscripts`).then(
    (response: any) => {
      dispatch(fetchTranscriptsCompleted(response.data[0]));
    },
    (error) => {}
  );
};

const fetchTranscriptsCompleted = (transcripts: Config) => ({
  payload: transcripts,
  type: actionTypes.FETCH_TRANSCRIPTS_COMPLETED,
});

export const createRecallAction = (recall: Recall, cb?: Function) => (dispatch: any) => {
  client.post(`/api/CreateRecall`, recall).then(
    (response: any) => {
      dispatch(createRecallCompleted(response.data));
      dispatch(fetchRecallsAction("current"));
      cb && cb(recall);
    },
    (error) => {
      dispatch(createRecallCompleted({}));
    }
  );
};

const createRecallCompleted = (response: any) => ({
  payload: response,
  type: actionTypes.POST_CREATE_RECALL_COMPLETED,
});

export const updateRecallAction = (recall: Recall) => (dispatch: any) => {
  client.post(`/api/UpdateRecall`, recall).then(
    (response: any) => {
      dispatch(updateRecallCompleted(response.data));
      dispatch(fetchRecallsAction("current"));
    },
    (error) => {
      dispatch(updateRecallCompleted({}));
    }
  );
};

const updateRecallCompleted = (response: any) => ({
  payload: response,
  type: actionTypes.POST_UPDATE_RECALL_COMPLETED,
});

export const sendTranscriptPreviewAction =
  (phoneNumber: string, transcriptText: string) => (dispatch: any) => {
    const body = {
      callText: transcriptText,
      phoneNumber: phoneNumber,
    };

    client.post(`/api/SendRecall`, body).then(
      (response: any) => {
        dispatch(sendTranscriptPreviewCompleted(response.data));
      },
      (error) => {
        dispatch(sendTranscriptPreviewCompleted({}));
      }
    );
  };

const sendTranscriptPreviewCompleted = (response: any) => ({
  payload: response,
  type: actionTypes.SEND_TRANSCRIPT_COMPLETED,
});

export const sendMarkCompleteAction = (recallId: number) => (dispatch: any) => {
  const body = {
    id: recallId,
  };

  client.post(`/api/markCompleted`, body).then(
    (response: any) => {
      dispatch(sendMarkCompleteCompleted(response.data));
    },
    (error) => {
      dispatch(sendMarkCompleteCompleted({}));
    }
  );
};

const sendMarkCompleteCompleted = (response: any) => ({
  payload: response,
  type: actionTypes.SEND_MARK_COMPLETE_COMPLETED,
});

export const sendMarkDeletedAction =
  (recallId: number, reason: string) => (dispatch: any) => {
    const body = {
      id: recallId,
      reason: reason,
    };

    client.post(`/api/markDeleted`, body).then(
      (response: any) => {
        dispatch(sendMarkDeletedCompleted(response.data));
      },
      (error) => {
        dispatch(sendMarkDeletedCompleted({}));
      }
    );
  };

const sendMarkDeletedCompleted = (response: any) => ({
  payload: response,
  type: actionTypes.SEND_MARK_DELETED_COMPLETED,
});
