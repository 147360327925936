import { connect } from 'react-redux';
import { State } from '../reducers';
import { ConfigPage } from './configPage';
import {fetchConfigAction, updateConfigAction} from "../actions/configAction";
import {Config} from "../models/config";


const mapStateToProps = (state: State) => ({
    config: state.config
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchConfig: () => dispatch(fetchConfigAction()),
    updateConfig: (config:Config) => dispatch(updateConfigAction(config))
});

export const ConfigPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfigPage);