import { connect } from 'react-redux';
import { State } from '../reducers';
import {Recall} from "../models";
import {
    createRecallAction,
    fetchTranscriptsAction,
    sendTranscriptPreviewAction,
} from "../actions/recallAction";
import {CreateRecall} from "./createRecall";
import {fetchStoresAction} from "../actions/storesAction";


const mapStateToProps = (state: State) => ({
    recall: state.recall,
    stores: state.stores,
    transcripts: state.transcripts
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchStores: () => dispatch(fetchStoresAction()),
    fetchTranscripts: () => dispatch(fetchTranscriptsAction()),
    saveCreateRecall: (recall: Recall, cb?: Function) => dispatch(createRecallAction(recall, cb)),
    sendTranscript: (phoneNumber: string, transcriptText: string) => dispatch(sendTranscriptPreviewAction(phoneNumber, transcriptText)),
});

export const CreateRecallContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateRecall);