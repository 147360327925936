import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { AppRouter } from "./router";
import { Configuration, EventType, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

// const DO_NOT_LOGIN = false;

// runWithAdal(authContext, () => { ReactDOM.render(<AppRouter />, document.getElementById('root'))},DO_NOT_LOGIN);

const msalConfig: Configuration = {
  auth: {
      clientId: "5a0036b7-84c7-4e7f-ab8a-a5cf65aac6fd",
      authority: "https://login.microsoftonline.com/bad19fcf-d456-441f-8627-6c6c1b737341"
  }
};

const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig)

msalInstance.initialize().then(() => {
    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }
  
    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();
  
    msalInstance.addEventCallback((event) => { //@ts-ignore
      if (event && event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        //@ts-ignore
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      }
    });
})

const AppProvider = () => (
    <MsalProvider instance={msalInstance}>
        <AppRouter msalInstance={msalInstance}/>
    </MsalProvider>
)

ReactDOM.render(<AppProvider />, document.getElementById("root"))