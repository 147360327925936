export const startLoadingAction = () => (dispatch: any) => {
  dispatch({
    type: 'LOADING'
  })
}

export const stopLoadingAction = () => (dispatch: any) => {
  dispatch({
    type: 'NOT_LOADING'
  })
}