export const actionTypes = {
    FETCH_CONFIG_COMPLETED: 'FETCH_CONFIG_COMPLETED',
    FETCH_ALL_RECALLS_COMPLETED: 'FETCH_ALL_RECALLS_COMPLETED',
    FETCH_RECALLS_COMPLETED: 'FETCH_RECALLS_COMPLETED',
    FETCH_RECALL_COMPLETED: 'FETCH_RECALL_COMPLETED',
    FETCH_STORES_COMPLETED: 'FETCH_STORES_COMPLETED',
    FETCH_TRANSCRIPTS_COMPLETED: 'FETCH_TRANSCRIPTS_COMPLETED',
    POST_CREATE_RECALL_COMPLETED: 'POST_CREATE_RECALL_COMPLETED',
    POST_UPDATE_RECALL_COMPLETED: 'POST_UPDATE_RECALL_COMPLETED',
    POST_CONFIG_COMPLETED: 'POST_CONFIG_COMPLETED',
    SEND_TRANSCRIPT_COMPLETED: 'SEND_TRANSCRIPT_COMPLETED',
    SEND_MARK_COMPLETE_COMPLETED: 'SEND_TRANSCRIPT_COMPLETED',
    SEND_MARK_DELETED_COMPLETED: 'SEND_MARK_DELETED_COMPLETED'
};