import { connect } from 'react-redux';
import { State } from '../reducers';
import { RecallsList } from './recallsList';
import {
    createRecallAction,
    fetchRecallsAction,
    fetchTranscriptsAction,
    sendMarkCompleteAction, 
    sendMarkDeletedAction,
    sendTranscriptPreviewAction,
} from "../actions/recallAction";
import {
  startLoadingAction,
  stopLoadingAction
} from '../actions/loadingAction';
import {Recall} from "../models";
import {fetchStoresAction} from "../actions/storesAction";


const mapStateToProps = (state: State) => ({
    recalls: state.recalls,
    stores: state.stores,
    transcripts: state.transcripts,
    loading: state.loading
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchRecalls: (type: string) => dispatch(fetchRecallsAction(type)),
    fetchStores: () => dispatch(fetchStoresAction()),
    createRecall: (recall: Recall) => dispatch(createRecallAction(recall)),
    fetchTranscripts: () => dispatch(fetchTranscriptsAction()),
    sendTranscript: (phoneNumber: string, transcriptText: string) => dispatch(sendTranscriptPreviewAction(phoneNumber, transcriptText)),
    sendMarkCompleteAction: (recallId: number) => dispatch(sendMarkCompleteAction(recallId)),
    sendMarkDeleteAction: (recallId: number, reason: string) => dispatch(sendMarkDeletedAction(recallId, reason)),
    startLoading: () => dispatch(startLoadingAction()),
    stopLoading: () => dispatch(stopLoadingAction())
});

export const RecallsListContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RecallsList);