import * as React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link } from "react-router-dom";
import "../node_modules/font-awesome/css/font-awesome.css";
import "./index.css";
import { Recall } from "./models";
import { purgeTwilioQueue } from "./actions/recallAction";

interface Props {
  allRecalls?: Recall[];
  fetchAllRecalls?(): void;
}

export class App extends React.Component<Props, { recalls: any[] }> {
  constructor(props: any) {
    super(props);

    this.state = {
      recalls: [],
    };
  }

  componentDidMount(): void {
    this.props.fetchAllRecalls && this.props.fetchAllRecalls();
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.allRecalls && Array.isArray(nextProps.allRecalls)) {
      this.setState({ recalls: nextProps.allRecalls });
    }
  }


  handleClickPurgeQueue = async () => {
    const confirmed = window.confirm('Are you sure you want to remove all outbound calls in the Twilio queue?');
    if(confirmed) purgeTwilioQueue();
  }

  render() {
    const searchCriteria = [];
    for (const recall of this.state.recalls) {
      searchCriteria.push({ id: recall.id, label: recall.name });
    }

    return (
      <div className="app">
        <div className="header">
          <Typeahead
            id="typeahead"
            placeholder="&#xF002; Search"
            onChange={(selected: any) => {
              if (selected[0] && selected[0].id) {
                window.location.href = `/recall/${selected[0].id}`;
                window.location.reload();
              }
            }}
            options={searchCriteria}
          />
          <div className="buttonRow">
            <button style={{ float: 'none', fontSize: '12px', margin: '0px 4px', height: '27px'}} onClick={this.handleClickPurgeQueue}>Purge Twilio Queue</button>
            <Link to="/timeline">
              <i className="fa fa-lg fa-line-chart settings"></i>
            </Link>
            <Link to="/config">
              <i className="fa fa-lg fa-gear settings"></i>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
