import { combineReducers } from 'redux';
import {Recall} from "../models/recall";
import {Store} from "../models/store";
import {Transcripts} from "../models/transcripts";
import {recallReducer, allRecallsReducer, recallsReducer, transcriptsReducer} from "./recallsReducer";
import {storesReducer} from "./storesReducer";
import {Config} from "../models/config";
import {configReducer} from "./configReducer";
import loadingReducer from './loadingReducer';

export interface State {
    config: Config;
    recall: Recall;
    allRecalls: Recall[];
    recalls: Recall[];
    stores: Store[];
    transcripts: Transcripts;
    loading: boolean;
};

export const state = combineReducers({
    config: configReducer,
    recall: recallReducer,
    allRecalls: allRecallsReducer,
    recalls: recallsReducer,
    stores: storesReducer,
    transcripts: transcriptsReducer,
    loading: loadingReducer
});