import * as React from "react";

import { Recall } from "../models/recall";
import { RecallDetail } from "./recallDetail";
import { Store } from "../models";
import { Config } from "../models/config";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";

interface Props {
  recallType: string;
  recalls: Recall[];
  stores: Store[];
  transcripts: Config;
  fetchTranscripts(): void;
  createRecall(recall: Recall): void;
  fetchRecalls(type: string): void;
  fetchStores(): void;
  sendTranscript(phoneNumber: string, transcriptText: string): void;
  sendMarkCompleteAction(recallId: number): void;
  sendMarkDeleteAction(recallId: number, reason: string): void;
  location: any;
  startLoading(): void;
  stopLoading(): void;
  loading?: boolean;
}

export class RecallsList extends React.Component<
  Props,
  { selectedRecall: Recall | null }
> {
  interval: any;
  constructor(props: any) {
    super(props);


    this.state = {
      selectedRecall: null
    };
    this.fetchRecalls = this.fetchRecalls.bind(this);
    this.selectRecall = this.selectRecall.bind(this);
  }

  //  componentDidUpdate(newProps: any) {
  //    if (this.props.location.pathname !== newProps.location.pathname) {
  //      this.setState({ selectedRecall: null });
  //      this.fetchRecalls();
  //    } else if (
  //      newProps.recalls !== this.props.recalls &&
  //      this.state.selectedRecall
  //    ) {
  //      for (const recall of newProps.recalls) {
  //        if (this.state.selectedRecall.id === recall.id) {
  //          this.setState({ selectedRecall: recall });
  //        }
  //      }
  //    }
  //  }

  componentDidMount() {
    this.props.startLoading();
    this.props.fetchTranscripts();
    this.props.fetchStores();
    this.fetchRecalls();

    const dataRefreshIntervalMinutes = 3;
    this.interval = setInterval(() => {
      this.props.fetchTranscripts();
      this.props.fetchStores();
      this.fetchRecalls();
    }, dataRefreshIntervalMinutes * 1000 * 60)
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate() {
    setTimeout(() => this.props.stopLoading(), 2000)
  }

  fetchRecalls() {
    this.props.fetchRecalls(this.props.recallType.toLowerCase());
  }

  selectRecall = (recall: Recall) => (e: any) => {
    this.setState({ selectedRecall: recall });
  };

  render() {
    return (
      <div className="recallContainer">
        <div className="titleRow">     
          <Link to="/recall" className="createRecall">
            CREATE NEW
          </Link>{" "}
          <h5 className="recallNotice">
            {this.props.recallType} Recall Notices <Loading active={this.props.loading} />
          </h5>
        </div>
        <div className="recallListContainer">
          <div className="recalls">
            {this.props.recalls.length === 0 ? (
              <Link to="/recall" className="recall">
                <i className="addIcon"> </i>
                <span className="addText">Add New</span>
              </Link>
            ) : null}
            {this.props.recalls.map((recall, i) => {
              return (
                <div
                  onClick={this.selectRecall(recall)}
                  key={i}
                  className={
                    "recall " +
                    (this.state.selectedRecall === recall ? "selected" : "")
                  }
                >
                  {recall.name}
                </div>
              );
            })}
          </div>
          <div className="recallDisplay">
            {this.state.selectedRecall ? (
              <RecallDetail
                stores={this.props.stores}
                recall={this.state.selectedRecall}
                sendMarkCompleteAction={this.props.sendMarkCompleteAction}
                sendMarkDeletedAction={this.props.sendMarkDeleteAction}
              />
            ) : (
              <p className="activeRecall">
                Please select an active recall or create a new one.
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
