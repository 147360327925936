import { actionTypes } from '../constants/actionTypes';
import { Recall } from "../models/recall";
import {Transcripts} from "../models/transcripts";

export const allRecallsReducer = (state:Recall[] = [], action:any) => {
    switch (action.type) {
        case actionTypes.FETCH_ALL_RECALLS_COMPLETED:
            return handleFetchAllRecallsCompleted(state, action.payload);
    }

    return state;
}

const handleFetchAllRecallsCompleted = (state: Recall[], payload: Recall[]): Recall[] => {
    return payload;
};

export const recallsReducer = (state:Recall[] = [], action:any) => {
    switch (action.type) {
        case actionTypes.FETCH_RECALLS_COMPLETED:
            return handleFetchRecallsCompleted(state, action.payload);
    }

    return state;
}

const handleFetchRecallsCompleted = (state: Recall[], payload: Recall[]): Recall[] => {
    return payload;
};

export const recallReducer = (state:Recall = {} as Recall, action:any) => {
    switch (action.type) {
        case actionTypes.FETCH_RECALL_COMPLETED:
            return handleFetchRecallCompleted(state, action.payload);
    }

    return state;
}

const handleFetchRecallCompleted = (state: Recall, payload: Recall): Recall => {
    return payload;
};

export const transcriptsReducer = (state:any = {}, action:any) => {
    switch (action.type) {
        case actionTypes.FETCH_TRANSCRIPTS_COMPLETED:
            return handleFetchTranscriptsCompleted(state, action.payload);
        case actionTypes.POST_UPDATE_RECALL_COMPLETED:
            window.location.href = '/#/current'
    }

    return state;
}

const handleFetchTranscriptsCompleted = (state: Transcripts, payload: Transcripts): Transcripts => {
    return payload;
};
