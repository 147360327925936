import { actionTypes } from '../constants/actionTypes';
import { Store } from '../models';

export const storesReducer = (state:Store[] = [], action:any) => {
    switch (action.type) {
        case actionTypes.FETCH_STORES_COMPLETED:
            return handleFetchStoresCompleted(state, action.payload);
    }

    return state;
}

const handleFetchStoresCompleted = (state: Store[], payload: Store[]): Store[] => {
    return payload;
};
