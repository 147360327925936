import moment from "moment";
import * as React from "react";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { Recall } from "../models";

interface Props {
  allRecalls: Recall[];
  fetchAllRecalls(): void;
}

export class TimelinePage extends React.Component<Props, {}> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.fetchAllRecalls();
  }

  render() {
    return (
      <div className="timelineContainer">
        <h5 className="recallNotice">Recall Timeline</h5>
        <Timeline>
          {this.props.allRecalls &&
            this.props.allRecalls.map((recall, i) => {
              const recallDate: any = recall.sendDate;
              return (
                <TimelineEvent
                  key={i}
                  title={recall.name}
                  createdAt={moment(recall.sendDate).format("lll")}
                  icon={
                    <i
                      className={
                        "fa " +
                        (recall.completed || recall.deleted
                          ? "fa-check"
                          : new Date(recallDate) > new Date()
                          ? "fa-calendar"
                          : "fa-circle-o-notch fa-spin")
                      }
                    ></i>
                  }
                >
                  <ul>
                    {recall.products &&
                      recall.products.map((product, t) => {
                        return <li key={t}>{product.name}</li>;
                      })}
                  </ul>
                </TimelineEvent>
              );
            })}
        </Timeline>
      </div>
    );
  }
}
