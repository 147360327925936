import { connect } from 'react-redux';
import { State } from '../reducers';
import { TimelinePage } from './timelinePage';
import {fetchAllRecallsAction} from "../actions/recallAction";


const mapStateToProps = (state: State) => ({
    allRecalls: state.allRecalls
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchAllRecalls: () => dispatch(fetchAllRecallsAction()),
});

export const TimelinePageContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TimelinePage);